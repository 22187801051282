import { Input } from '@cuttingroom/core-components';
import React, { useContext } from 'react';
import { LoginContext } from '../LoginProvider';
import { EMAIL_WORKSPACE_INPUT, PASSWORD_INPUT } from '../utils/interface';

interface EmailInputProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  workspaceExpanded: boolean;
}

const EmailInput: React.FC<EmailInputProps> = ({
  email,
  setEmail,
  workspaceExpanded,
}) => {
  const { step } = useContext(LoginContext);

  return (
    <div>
      <p>
        Please enter your email
        {step === PASSWORD_INPUT && ', password '}
        {workspaceExpanded && ' and workspace'}
        {' '}
        to access CuttingRoom
      </p>
      <Input
        className="email dark"
        label="Email"
        type="email"
        autoFocus
        required={step === EMAIL_WORKSPACE_INPUT}
        clearable={false}
        value={email}
        onChange={(e: { target: { value: string } }) => setEmail(e.target.value)}
      />
    </div>
  );
};

export default EmailInput;
