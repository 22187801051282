import { Button, Input, Notice } from '@cuttingroom/core-components';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ClickableText from '../common/ClickableText';
import LandingPageBG from '../LandingPageBG/LandingPageBG';
import './style.scss';
import { activateAccount } from './utility';

const PASSWORD_POLICY = 'Minimum 8 characters. Needs to include at least one number, one uppercase and lowercase letter and one symbol.';

const SuccessMessage = () => {
  const navigate = useNavigate();

  return (
    <div className="success-message">
      <h1>
        Congratulations! Your account has been activated. Please navigate to
        login page and sign in with your credentials.
      </h1>

      <Button className="button primary" onClick={() => navigate('/login')}>
        Login
      </Button>
    </div>
  );
};

const ActivateAccount = () => {
  const [email, setEmail] = useState('');
  const [tenant, setTenant] = useState('');
  const [temporaryPassword, setTemporaryPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { search, hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(search + hash);
    const queryEmail = searchParams.get('email');
    const queryTenant = searchParams.get('tenant');
    const queryPassword = searchParams.get('password');

    if (queryEmail && queryTenant && queryPassword) {
      setEmail(queryEmail);
      setTenant(queryTenant);
      setTemporaryPassword(queryPassword);
    } else {
      navigate('/');
    }
  }, [hash, navigate, search]);

  const activateAccountHandler = (
    event: React.ChangeEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    const payload = { email, tenant, password: temporaryPassword, newPassword };
    const methods = { setLoading, setErrorMessage, setSuccessMessage };

    activateAccount(payload, methods);
  };

  return (
    <LandingPageBG>
      {successMessage ? (
        <SuccessMessage />
      ) : (
        <form
          className="activate-account-input-form"
          onSubmit={activateAccountHandler}
        >
          <h3 style={{ margin: '1rem 0 0 0' }}>Activate your account</h3>
          <p>Please enter a new password to activate your account</p>

          <Input
            className="password dark"
            label="Password"
            type="password"
            clearable={false}
            value={newPassword}
            onChange={(e: { target: { value: string } }) => setNewPassword(e.target.value)}
          />

          <Notice infoMessage={PASSWORD_POLICY} />

          <Input
            className="password dark"
            label="Confirm password"
            type="password"
            clearable={false}
            value={confirmNewPassword}
            onChange={(e: { target: { value: string } }) => setConfirmNewPassword(e.target.value)}
          />

          {newPassword.length > 0
            && confirmNewPassword.length > 0
            && newPassword !== confirmNewPassword && (
              <Notice errorMessage="The passwords you have entered does not match each other." />
          )}

          {errorMessage && <Notice errorMessage={errorMessage} />}

          <ClickableText
            title="Reset password"
            action={() => navigate('/recover-password')}
          />

          <Button
            type="submit"
            className="button primary"
            disabled={
              !newPassword.length
              || !confirmNewPassword.length
              || newPassword !== confirmNewPassword
              || loading
            }
            loading={loading}
          >
            Activate account
          </Button>
        </form>
      )}
    </LandingPageBG>
  );
};

export default ActivateAccount;
