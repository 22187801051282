import { Input } from '@cuttingroom/core-components';
import React from 'react';

interface PasswordInputProps {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  setPassword,
  errorMessage,
}) => (
  <Input
    className="password dark"
    label="Password"
    type="password"
    clearable={false}
    value={password}
    autoFocus
    errorMessage={errorMessage.length > 0 ? errorMessage : ''}
    required
    onChange={(e: { target: { value: string } }) => setPassword(e.target.value)}
  />
);

export default PasswordInput;
