import React, { useEffect, useState } from 'react';
import { Button } from '@cuttingroom/core-components';
import { AuthenticateHandlerOperation, CHECK_SSO } from '../utils/interface';
import Loader from '../../common/Loader';
import * as config from '../utils/interface';

type Props = {
  tenantList: string[];
  loading: boolean;
  workspace: string;
  setWorkspace: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<config.LoginStepType>>,
  authenticateHandler: (
    _event: React.ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line no-unused-vars
    operation: AuthenticateHandlerOperation
  ) => void;
};

const TenantSelection = ({
  tenantList,
  loading,
  workspace,
  setWorkspace,
  setStep,
  authenticateHandler,
}: Props) => {
  const [event, setEvent] = useState({} as React.ChangeEvent<HTMLInputElement>);

  const handleWorkspaceSelection = (e: React.ChangeEvent<HTMLInputElement>, tenant: string) => {
    setWorkspace(tenant);
    setEvent(e);
  };

  useEffect(() => {
    if (workspace) {
      authenticateHandler(event, CHECK_SSO);
    }
  }, [workspace, authenticateHandler, event]);

  return (
    <div className="tenant-selection">
      {loading ? <Loader /> : (
        <>
          <h3>Select your tenant</h3>
          {tenantList.map((tenant) => (
            <div key={tenant} className="selection-area">
              <div className="tenant-name">{tenant}</div>
              <Button
                type="button"
                className="select-button"
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => handleWorkspaceSelection(e, tenant)}
              >
                Select
              </Button>
            </div>
          ))}

          <Button className="change-email" onClick={() => setStep(config.EMAIL_WORKSPACE_INPUT)}>Back</Button>
        </>
      )}
    </div>
  );
};

export default TenantSelection;
