import { Button } from '@cuttingroom/core-components';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClickableText from '../common/ClickableText';
import EmailInput from './input-components/EmailInput';
import MFAInput from './input-components/MFAInput';
import PasswordInput from './input-components/PasswordInput';
import SetupMFA from './input-components/SetupMFA';
import WorkspaceInput from './input-components/WorkspaceInput';
import NewPasswordInput from './input-components/NewPasswordInput';
import TenantSelection from './input-components/TenantSelection';
import { LoginContext } from './LoginProvider';
import * as config from './utils/interface';
import { authenticate,
  checkSso,
  authenticateMFA,
  authenticatePassword,
  fetchTenantList,
} from './utils/utility';

const LoginForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [secretCode, setSecretCode] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const navigate = useNavigate();

  const {
    step,
    setStep,
    setSsoConfig,
    email,
    password,
    setEmail,
    setPassword,
    workspace,
    setWorkspace,
    workspaceExpanded,
    setWorkspaceExpanded,
    tenantList,
    setTenantList,
  } = useContext(LoginContext);

  useEffect(() => {
    const ssoConfig: config.SSOConfig = JSON.parse(localStorage.getItem(config.SSO_CONFIG) || '{}');
    if (ssoConfig.tenant && !workspace) {
      setWorkspace(ssoConfig.tenant);
      setWorkspaceExpanded(true);
    }

    if (ssoConfig && Object.keys(ssoConfig).length) {
      setSsoConfig(ssoConfig);
      if (step === config.EMAIL_WORKSPACE_INPUT) {
        setStep(config.PASSWORD_INPUT);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEmail, setSsoConfig, setStep, setWorkspace, setWorkspaceExpanded, step]);

  const authenticateHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    operation: config.AuthenticateHandlerOperation = config.CHECK_SSO,
  ) => {
    event.preventDefault();
    const commonMethods = {
      setStep,
      setLoading,
    };

    const commonPayload = {
      email,
      password,
      tenant: workspace,
    };
    switch (operation) {
      case config.CHECK_SSO:
      case config.FETCH_TENANT_LIST: {
        if (workspace) {
          checkSso({ email, tenant: workspace }, { ...commonMethods, setSsoConfig });
        } else {
          fetchTenantList({ email }, { ...commonMethods, setTenantList });
        }
        break;
      }
      case config.AUTHENTICATE:
        authenticate(commonPayload, {
          ...commonMethods,
          setErrorMessage,
          setSecretCode,
        });
        break;
      case config.AUTHENTICATE_MFA:
        authenticateMFA(
          { ...commonPayload, mfaCode, password: newPassword || password },
          { setLoading, setErrorMessage },
        );
        break;
      case config.AUTHENTICATE_PASSWORD:
        authenticatePassword(
          { ...commonPayload, newPassword },
          { ...commonMethods, setErrorMessage, setSecretCode },
        );
        break;
      default:
        checkSso(
          { email, tenant: workspace },
          { setStep, setSsoConfig, setLoading },
        );
    }
  };

  return (
    <form className="login-form">
      {[config.EMAIL_WORKSPACE_INPUT, config.PASSWORD_INPUT].includes(step) && (
        <EmailInput
          {...{
            email,
            setEmail,
            workspaceExpanded,
          }}
        />
      )}

      {step === config.TENANT_SELECTION && (
        <TenantSelection {...{ tenantList, loading, workspace, setWorkspace, setStep, authenticateHandler }} />
      )}

      {step === config.PASSWORD_INPUT && (
        <PasswordInput {...{ password, setPassword, errorMessage }} />
      )}

      {step === config.NEW_PASSWORD_REQUIRED && (
        <NewPasswordInput
          {...{
            newPassword,
            setNewPassword,
            errorMessage,
            loading,
            authenticateHandler,
          }}
        />
      )}

      {[config.EMAIL_WORKSPACE_INPUT, config.PASSWORD_INPUT].includes(step)
        && workspaceExpanded && (
          <WorkspaceInput
            {...{
              workspace,
              setWorkspace,
              workspaceExpanded,
              setWorkspaceExpanded,
            }}
          />
      )}

      {step === config.MFA_CODE_MISSING && (
        <MFAInput
          {...{
            mfaCode,
            setMfaCode,
            loading,
            errorMessage,
            authenticateHandler,
          }}
        />
      )}

      {step === config.MFA_SETUP_REQUIRED && (
        <SetupMFA
          {...{
            email,
            secretCode,
            mfaCode,
            setMfaCode,
            setPassword,
            loading,
            errorMessage,
            authenticateHandler,
          }}
        />
      )}

      <p style={{ display: 'flex', justifyContent: 'space-between' }}>
        {[config.PASSWORD_INPUT].includes(step) && (
          <ClickableText
            title="Forgot password?"
            action={() => navigate(`/recover-password?email=${email}&tenant=${workspace}`)}
          />
        )}
      </p>

      {[config.EMAIL_WORKSPACE_INPUT, config.PASSWORD_INPUT].includes(step) && (
        <Button
          type="submit"
          className="button primary"
          disabled={
            email.length === 0
            || (step === config.PASSWORD_INPUT && password.length === 0)
            || loading
          }
          inProgress={loading}
          onClick={(e: React.ChangeEvent<HTMLInputElement>) => authenticateHandler(
            e,
            step === config.EMAIL_WORKSPACE_INPUT
              ? config.FETCH_TENANT_LIST
              : config.AUTHENTICATE,
          )}
        >
          {(step === config.EMAIL_WORKSPACE_INPUT && 'Continue') || 'Sign in'}
        </Button>
      )}
    </form>
  );
};

export default LoginForm;
