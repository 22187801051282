import React from 'react';
import logo from '../../assets/img/cr-logo-white.svg';
import './style.scss';

interface Props {
  // eslint-disable-next-line no-undef
  children: JSX.Element
}

const LandingPageBG:React.FC<Props> = ({ children }) => (
  <div className="landing-page-bg">
    <a href="/"><img className="logo" src={logo} alt="Logo" /></a>
    {children}
  </div>
);

export default LandingPageBG;
