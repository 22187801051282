import React from 'react';
import { toast, ToastPosition } from 'react-toastify';
import { universalPostRequest } from '../../use/universalRequests';

const { REACT_APP_API_BASE_URL = '' } = process.env;
const TOAST_POSITION: ToastPosition = 'top-center';

export const passwordPolicyRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

interface ConfirmRecoverPasswordMethods {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

interface ConfirmRecoverPasswordResponse {
  message: string;
}

interface ConfirmRecoverPasswordPayload {
  email: string;
  tenant: string;
  code: string;
  password: string;
}

export const confirmRecoverPassword = async (
  payload: ConfirmRecoverPasswordPayload,
  methods: ConfirmRecoverPasswordMethods,
) => {
  const { setLoading, setErrorMessage, setSuccessMessage } = methods;
  setLoading(true);

  const id = toast.loading('Recovering password...', {
    position: TOAST_POSITION,
  });

  const url = `${REACT_APP_API_BASE_URL}/auth/recover-password/confirm`;

  const response = await universalPostRequest<
    ConfirmRecoverPasswordResponse,
    ConfirmRecoverPasswordPayload
  >(url, payload);

  if (response && response.data && response.status === 200) {
    setErrorMessage('');
    setSuccessMessage(response.data.message);
    toast.update(id, {
      render: response.data.message,
      type: 'success',
      isLoading: false,
      autoClose: 2000,
      position: TOAST_POSITION,
    });
  } else {
    const { error = 'Something went wrong. Please try again.' } = response;
    setErrorMessage(error);

    toast.update(id, {
      render: error,
      type: 'error',
      isLoading: false,
      autoClose: 2000,
      position: TOAST_POSITION,
    });
  }

  setLoading(false);
};
