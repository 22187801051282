import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const isLogout = searchParams.get('logout');

  useEffect(() => {
    if (isLogout) {
      localStorage.removeItem('authInfo');
    }
    navigate('/login');
  }, [isLogout, navigate]);

  return <div />;
};

export default Home;
