import { Input } from '@cuttingroom/core-components';
import React from 'react';

interface WorkspaceInputProps {
  workspace: string;
  setWorkspace: React.Dispatch<React.SetStateAction<string>>;
}

const WorkspaceInput: React.FC<WorkspaceInputProps> = ({
  workspace,
  setWorkspace,
}) => (
  <Input
    className="workspace dark"
    label="Your CuttingRoom"
    type="text"
    value={workspace}
    clearable={false}
    showOptionsOnFocus
    onChange={(e: { target: { value: string } }) => setWorkspace(e.target.value)}
  />
);

export default WorkspaceInput;
