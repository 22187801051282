import { Button, Input, Notice } from '@cuttingroom/core-components';
import React, { useState } from 'react';
import {
  AuthenticateHandlerOperation,
  AUTHENTICATE_PASSWORD,
} from '../utils/interface';

interface NewPasswordInputProps {
  newPassword: string;
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string;
  loading: boolean;
  authenticateHandler: (
    _event: React.ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line no-unused-vars
    operation: AuthenticateHandlerOperation
  ) => void;
}

const NewPasswordInput: React.FC<NewPasswordInputProps> = ({
  newPassword,
  setNewPassword,
  errorMessage,
  loading,
  authenticateHandler,
}) => {
  const [confirmPassword, setConfirmPassword] = useState('');
  const passwordPolicy = 'Minimum 8 characters. Needs to include at least one number, one uppercase and lowercase letter and one symbol.';

  return (
    <>
      <h3 style={{ margin: '1rem 0' }}>Change temporary password</h3>

      <Input
        className="password dark"
        label="New Password"
        type="password"
        clearable={false}
        autoFocus
        value={newPassword}
        required
        onChange={(e: { target: { value: string } }) => setNewPassword(e.target.value)}
      />

      {(errorMessage.includes('Password does not conform to policy') && (
        <Notice errorMessage={passwordPolicy} />
      )) || <Notice infoMessage={passwordPolicy} />}

      <Input
        className="password dark"
        label="Confirm new password"
        type="password"
        clearable={false}
        value={confirmPassword}
        required
        onChange={(e: { target: { value: string } }) => setConfirmPassword(e.target.value)}
      />

      <Button
        className="button primary"
        disabled={
          newPassword.length === 0 || newPassword !== confirmPassword || loading
        }
        inProgress={loading}
        onClick={(e: React.ChangeEvent<HTMLInputElement>) => authenticateHandler(e, AUTHENTICATE_PASSWORD)}
      >
        Verify
      </Button>
    </>
  );
};

export default NewPasswordInput;
