import React, { createContext, ReactNode, useMemo, useState } from 'react';
import { ContextInterface, LoginStepType, SSOConfig, EMAIL_WORKSPACE_INPUT } from './utils/interface';

export const LoginContext = createContext({} as ContextInterface);

interface LoginProviderProps {
  children: ReactNode;
}

const LoginProvider = ({ children }: LoginProviderProps) => {
  const [step, setStep] = useState<LoginStepType>(EMAIL_WORKSPACE_INPUT);
  const [ssoConfig, setSsoConfig] = useState<SSOConfig | false>(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [workspace, setWorkspace] = useState('');
  const [workspaceExpanded, setWorkspaceExpanded] = useState(false);
  const [tenantList, setTenantList] = useState(['']);

  const contextValue = useMemo(
    () => ({
      step,
      setStep,
      ssoConfig,
      setSsoConfig,
      email,
      setEmail,
      password,
      setPassword,
      workspace,
      setWorkspace,
      workspaceExpanded,
      setWorkspaceExpanded,
      tenantList,
      setTenantList,
    }),
    [step, ssoConfig, email, password, workspace, workspaceExpanded, tenantList],
  );

  return (
    <LoginContext.Provider value={contextValue}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
