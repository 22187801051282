import { Button, Input, Notice } from '@cuttingroom/core-components';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ClickableText from '../common/ClickableText';
import LandingPageBG from '../LandingPageBG/LandingPageBG';
import './style.scss';
import { recoverPassword } from './utility';

const SuccessMessage = () => {
  const navigate = useNavigate();

  return (
    <div className="success-message">
      <h1>
        Password recovery instructions has been sent to your email. Please check inbox and follow the steps to recover your password
      </h1>

      <Button className="button primary" onClick={() => navigate('/login', { replace: true })}>Login</Button>
    </div>
  );
};

const RecoverPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [tenant, setTenant] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const queryEmail = searchParams.get('email');
    const queryTenant = searchParams.get('tenant');

    if (queryEmail && queryTenant) {
      setEmail(queryEmail);
      setTenant(queryTenant);
      navigate({ pathname }, { replace: true });
    } else {
      navigate('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recoverPasswordHandler = (
    event: React.ChangeEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    const payload = { email, tenant };
    const methods = { setLoading, setErrorMessage, setSuccessMessage };

    recoverPassword(payload, methods, navigate);
  };

  return (
    <LandingPageBG>
      {successMessage ? <SuccessMessage /> : (
        <form
          className="recover-pass-input-form"
          onSubmit={recoverPasswordHandler}
        >
          <h3 style={{ margin: '1rem 0' }}>Recover password</h3>

          <Input
            className="email dark"
            label="Email"
            type="email"
            autoFocus
            clearable={false}
            locked
            value={email}
            onChange={(e: { target: { value: string } }) => setEmail(e.target.value)}
          />

          <Input
            className="workspace dark"
            label="Workspace"
            type="text"
            clearable={false}
            locked
            value={tenant}
            onChange={(e: { target: { value: string } }) => setTenant(e.target.value)}
          />

          {errorMessage && <Notice errorMessage={errorMessage} />}

          <ClickableText
            title="Back to login"
            action={() => navigate('/login')}
          />

          <Button
            type="submit"
            className="button primary"
            disabled={!email.length || !tenant.length || loading}
            loading={loading}
          >
            Reset
          </Button>
        </form>
      )}
    </LandingPageBG>
  );
};

export default RecoverPassword;
