import { Button, Input } from '@cuttingroom/core-components';
import React from 'react';
import {
  AuthenticateHandlerOperation,
  AUTHENTICATE_MFA,
} from '../utils/interface';

interface MFAInputProps {
  mfaCode: string;
  setMfaCode: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  errorMessage: string;
  authenticateHandler: (
    _event: React.ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line no-unused-vars
    operation: AuthenticateHandlerOperation
  ) => void;
}

const MFAInput: React.FC<MFAInputProps> = ({
  mfaCode,
  setMfaCode,
  loading,
  errorMessage,
  authenticateHandler,
}) => (
  <>
    <h3 style={{ margin: '1rem 0' }}>Enter MFA code</h3>

    <Input
      className="mfa dark"
      label="One time code"
      type="text"
      autoFocus
      clearable={false}
      errorMessage={errorMessage.length > 0 ? errorMessage : ''}
      value={mfaCode}
      required
      onChange={(e: { target: { value: string } }) => setMfaCode(e.target.value)}
    />

    <Button
      type="submit"
      className="button primary"
      disabled={mfaCode.length !== 6 || loading}
      inProgress={loading}
      onClick={(e: React.ChangeEvent<HTMLInputElement>) => authenticateHandler(e, AUTHENTICATE_MFA)}
    >
      Verify
    </Button>
  </>
);

export default MFAInput;
